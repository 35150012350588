import React, { useEffect, useRef } from "react";
import { navigate } from "gatsby";
import { Layout, BlockRichText } from "@components";
import { graphql } from "gatsby";
import "./blog-article.css";

const BlogArticleTemplate = ({ data }) => {
  const post = data?.allStrapiPost?.nodes?.[0];

  if (!post) {
    return <p>Nie znaleziono artykułu lub brak danych.</p>;
  }

  if (typeof window === "undefined") {
    return null;
  }

  const scrollToNode = (node) => {
    node.current.scrollIntoView({ behavior: "smooth" });
  };

  const postRef = useRef();

  useEffect(() => {
    scrollToNode(postRef);
  }, []);

  const navigationOptions = [
    { sectionStringCode: "main-page", scrollFunction: () => navigate("/") },
  ];

  return (
    <Layout navigationOptions={navigationOptions}>
      <article className="blog-article">
        <h1 ref={postRef}>{post.title}</h1>
        {post.image?.url && <img src={post.image.url} alt={post.title} />}
        <p style={{ textAlign: "right" }}>
          <em>
            Data publikacji artykułu:{" "}
            {new Date(post.publishedAt).toLocaleDateString()}
          </em>
        </p>
        <BlockRichText content={post.content?.data?.content ?? ""} />
        <p style={{ textAlign: "right" }}>
          <em>Autor: Magdalena Panasiuk</em>
        </p>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query GetPostBySlug($slug: String!) {
    allStrapiPost(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        title
        slug
        publishedAt
        image {
          url
        }
        content {
          data {
            content
          }
        }
      }
    }
  }
`;

export default BlogArticleTemplate;
